.text-orange {
    color: #ef6925;
}

.text-muted {
    color: #7987a1 !important;
}

.text-primary {
    color: #16C79A !important;
}

.text-secondary {
    color: #19456b !important;
}

.text-success {
    color: rgb(56 203 137) !important;
}

.text-warning {
    color: #ffc107 !important;
}

.text-danger {
    color: #fa5c7c !important;
}

.text-info {
    color: #02d7ff !important;
}

.text-indigo {
    color: #5066e0;
}

.text-purple {
    color: #8a4dd2;
}

.bg-primary,
.badge-primary {
    background-color: #16C79A !important;
}

.bg-success,
.label-success {
    background-color: #38cb89 !important;
}

.bg-info {
    background-color: rgb(2 215 255) !important;
}

.bg-warning,
.badge-warning {
    background-color: #ffc107 !important;
}

.bg-danger,
.badge-danger {
    background-color: #fa5c7c !important;
}

.bg-indigo {
    background: #5066e0 !important;
}

.bg-purple {
    background: #8a4dd2 !important;
}

.badge-purple {
    background: #8a4dd2 !important;
    color: #fff !important
}

.badge-indigo {
    background: #5066e0 !important;
    color: #fff !important
}

.bg-secondary {
    background: #19456b !important;
}

.bg-primary-transparent {
    background: rgba(22, 199, 154, .2) !important;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.bg-indigo-transparent {
    background: rgba(80, 102, 224, 0.2);
}

.bg-purple-transparent {
    background: rgba(138, 77, 210, 0.2);
}

.text-sm {
    font-size: .875rem;
}

.text-5 {
    font-size: 0.3rem !important;
}

.text-xs {
    font-size: .75rem;
}

.text-xl {
    font-size: 1.25rem;
}

.text-lg {
    font-size: 1.125rem;
}

.text-base {
    font-size: 1rem;
}

.text-2xl {
    font-size: 1.875rem;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.opacity-50 {
    opacity: 0.6;
}

.opacity-25 {
    opacity: .25;
}

.opacity-75 {
    opacity: .75;
}

.tabs-container .nav-tabs>li.active {
    border-bottom: 4px solid #16C79A;
    border-left: 0;
}

.rounded-sm {
    border-radius: .125rem !important;
}

.rounded-md {
    border-radius: .375rem !important;
}

.rounded-lg {
    border-radius: .5rem !important;
}

.rounded {
    border-radius: .25rem !important;
}

.rounded-xl {
    border-radius: .75rem !important;
}

.rounded-2xl {
    border-radius: 1rem !important;
}

.rounded-3xl {
    border-radius: 1.5rem !important;
}

.rounded-full {
    border-radius: 2rem !important;
}

.rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
}

.rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
}

.rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
}

.rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
}

.rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
}

.rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
}

.rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
}

.rounded-tl-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
}

.rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
}

.rounded-b-sm {
    border-bottom-left-radius: .125rem;
    border-bottom-right-radius: .125rem;
}

.rounded-b-md {
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem;
}

.rounded-b-lg {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.rounded-b-xl {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
}

.rounded-b-2xl {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.rounded-b-3xl {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

.rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.rounded-tl-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
}

.rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}

.rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

.media-icon {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: rgb(241 245 249);
}


.border {
    border: 1px solid #edeef7 !important;
}

.border-right {
    border-right: 1px solid #edeef7 !important;
}

.border-left {
    border-left: 1px solid #edeef7 !important;
}

.border-top {
    border-top: 1px solid #edeef7 !important;
}

.border-bottom {
    border-bottom: 1px solid #edeef7 !important;
}

.dropdown-divider {
    border-top: 1px solid #edeef7;
}

.pulse {
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgb(255 193 7);
    height: 22px;
    width: 22px;
    padding: 2px;
    transform: scale(1);
    animation: pulse 2s infinite;
    color: #fff;
    text-align: center;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
}

.pulse i {
    color: rgb(255 193 7);
}

.avatar {
    position: relative;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 500;
    font-size: .875rem;

    background: rgb(241 245 249);
}

.avatar-xs,
.media-icon-xs {
    width: 24px;
    height: 24px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: rgb(241 245 249);
}

.avatar-sm,
.media-icon-sm {
    width: 32px;
    height: 32px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: rgb(241 245 249);
}

.avatar-md,
.media-icon-md {
    width: 44px;
    height: 44px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: rgb(241 245 249);
}

.avatar-lg,
.media-icon-lg {
    width: 64px;
    height: 64px;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: rgb(241 245 249);
    display: inline-flex;
}

.avatar-xl,
.media-icon-xl {
    width: 72px;
    height: 72px;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: rgb(241 245 249);
}

.avatar-2xl,
.media-icon-2xl {
    width: 82px;
    height: 82px;
    font-size: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: rgb(241 245 249);
}

.h-5 {
    height: 5px !important;
}

.card-hover:hover {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: .3s;
    outline: none;
    text-decoration: none;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%) !important;

}

.rotate-46 {
    transform: rotate(46deg);
}

.-rotate-46 {
    transform: rotate(-46deg);
}

.bg-light {
    background: #f1f5f9 !important;
}

.bg-success-transparent {
    background: rgba(56, 203, 137, 0.1) !important;
}

.bg-danger-transparent {
    background: rgba(250, 92, 124, 0.1) !important;
}

.bg-info-transparent {
    background: rgba(2, 215, 255, 0.1) !important;
}

.bg-warning-transparent {
    background: rgba(255, 193, 7, 0.1) !important;
}

.bg-secondary-transparent {
    background: rgba(25, 69, 107, 0.1) !important;
}

.bg-purple-transparent {
    background: rgba(138, 77, 110, 0.1) !important;
}

.bg-light-transparent {
    background: rgba(241, 245, 249, 0.8) !important;
}

.btn:hover {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: .3s;
    outline: none;
    text-decoration: none;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}

.btn-primary {
    color: #fff;
    background-color: #16C79A;
    border-color: #16C79A;
}

.btn-primary:hover,
.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: #69ce94;
    border-color: #69ce94;
}

.btn-secondary {
    color: #fff;
    background-color: #19456b;
    border-color: #19456b;
}

.btn-secondary:hover,
.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    background-color: #236298;
    border-color: #236298;
}

.btn-outline-success {
    color: #38cb89;
    border-color: #38cb89;
}

.btn-success {
    color: #fff;
    background-color: #38cb89;
    border-color: #38cb89;
}

.btn-outline-primary {
    color: #16C79A;
    background-color: transparent;
    background-image: none;
    border-color: #16C79A;
}

.btn-outline-primary:hover {
    color: #FFF;
    background-color: #16C79A;
    border-color: #16C79A;
}

.btn-outline-danger {
    color: #fa5c7c !important;
    border-color: #fa5c7c;
}

.btn-outline-danger:hover {
    color: #fff !important;
    background-color: #fa5c7c;
    border-color: #fa5c7c;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-secondary.focus,
.btn-secondary:focus,
.btn-success.focus,
.btn-success:focus,
.btn-danger.focus,
.btn-danger:focus,
.btn-warning.focus,
.btn-warning:focus,
.btn-info.focus,
.btn-info:focus {
    box-shadow: none;
}

.file-upload input[type='file'] {
    display: none;
}

.b-solid {
    border: solid 1px #eff0f6;
}

.b-solid-2 {
    border: solid 2px #eff0f6;
}

.b-solid-3 {
    border: solid 3px #eff0f6;
}

.b-solid-4 {
    border: solid 4px #eff0f6;
}

.b-solid-5 {
    border: solid 5px #eff0f6;
}

.b-dashed {
    border: dashed 1px #eff0f6;
}

.b-dashed-dark {
    border: dashed 1px rgba(25, 69, 107, 0.5);
}

.b-dashed-2 {
    border: dashed 2px #eff0f6;
}

.b-dashed-3 {
    border: dashed 3px #eff0f6;
}

.b-dashed-4 {
    border: dashed 4px #eff0f6;
}

.b-dashed-5 {
    border: dashed 5px #eff0f6;
}

.card {
    background-color: #fff;
    position: relative;
    margin-bottom: 1.5rem;
    border: 0 !important;
    box-shadow: 0 5px 15px 5px rgb(241 244 251 / 80%) !important;
    border-radius: 1.25rem !important;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflow-visible {
    overflow: visible !important;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@media (min-width: 576px) {
    .sm--mx-1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important;
    }

    .sm--mx-2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important;
    }

    .sm--mx-3 {
        margin-left: -.75rem !important;
        margin-right: -.75rem !important;
    }

    .sm--mx-4 {
        margin-left: -1rem !important;
        margin-right: -1rem !important;
    }

    .sm--mx-5 {
        margin-left: -1.25rem !important;
        margin-right: -1.25rem !important;
    }
}

.w-0 {
    width: 0;
}

.h-0 {
    height: 0;
}

.w-10 {
    width: 0.625rem;
}

.h-10 {
    height: 0.625rem;
}

.z-10 {
    z-index: 10;
}

.bottom-0 {
    bottom: 0;
}

.right-0 {
    right: 0px !important;
}

.top-0 {
    top: 0px;
}

.left-0 {
    left: 0px;
}

.b-corner {
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    line-height: 0;
    border-bottom: 1.2em solid transparent;
    border-style: solid;
    border-width: 1.2em;
    bottom: 0px;
}

.b-corner-r {
    border-right: 1.2em solid transparent;
    right: 0;
}

.b-corner-l {
    border-left: 1.2em solid transparent;
    right: 0;
}

.b-corner-success {
    border-color: transparent #38cb89 #38cb89 transparent;
}

.b-corner-warning {
    border-color: transparent #ffc107 #ffc107 transparent;
}

.b-corner-danger {
    border-color: transparent #fa5c7c #fa5c7c transparent;
}

.b-corner-info {
    border-color: transparent rgb(2 215 255) rgb(2 215 255) transparent;
}

.b-corner-secondary {
    border-color: transparent #19456b #19456b transparent;
}

.vh-100 {
    height: 100vh;
}

.vh-50 {
    height: 50vh;
}

.min-vh-100 {
    min-height: 100vh;
}

.min-vh-70 {
    min-height: 70vh;
}

.min-vh-50 {
    min-height: 50vh;
}

.left-auto {
    left: auto !important;
}

/*---------Gradiants------------------------*/
.bg-gradient-indigo-primary {
    background-image: linear-gradient(134deg, #fff, #ccc);
    background-image: -webkit-gradient(linear, left top, right top, from(#5066e0), to(#16c79a));
}

.bg-gradient-purple-primary {
    background-image: linear-gradient(134deg, #8a4dd2, #16c79a);
    background-image: -webkit-gradient(linear, left top, right top, from(#8a4dd2), to(#16c79a));
}

.bg-gradient-warning-primary {
    background-image: linear-gradient(134deg, #ffc107, #16c79a);
    background-image: -webkit-gradient(linear, left top, right top, from(#ffc107), to(#16c79a));
}

.bg-gradient-danger-primary {
    background-image: linear-gradient(134deg, #fa5c7c, #16c79a);
    background-image: -webkit-gradient(linear, left top, right top, from(#fa5c7c), to(#16c79a));
}

.bg-gradient-info-primary {
    background-image: linear-gradient(134deg, #02d7ff, #16c79a);
    background-image: -webkit-gradient(linear, left top, right top, from(#02d7ff), to(#16c79a));
}

.bg-gradient-success-primary {
    background-image: linear-gradient(134deg, #38cb89, #16c79a);
    background-image: -webkit-gradient(linear, left top, right top, from(#38cb89), to(#16c79a));
}

.bg-gradient-success-secondary {
    background-image: linear-gradient(134deg, #38cb89, #19456b);
    background-image: -webkit-gradient(linear, left top, right top, from(#38cb89), to(#19456b));
}

.bg-gradient-info-secondary {
    background-image: linear-gradient(134deg, #02d7ff, #19456b);
    background-image: -webkit-gradient(linear, left top, right top, from(#02d7ff), to(#19456b));
}

.bg-gradient-danger-secondary {
    background-image: linear-gradient(134deg, #fa5c7c, #19456b);
    background-image: -webkit-gradient(linear, left top, right top, from(#fa5c7c), to(#19456b));
}

.bg-gradient-warning-secondary {
    background-image: linear-gradient(134deg, #ffc107, #19456b);
    background-image: -webkit-gradient(linear, left top, right top, from(#ffc107), to(#19456b));
}

.bg-gradient-indigo-secondary {
    background-image: linear-gradient(134deg, #5066e0, #19456b);
    background-image: -webkit-gradient(linear, left top, right top, from(#5066e0), to(#19456b));
}

.bg-gradient-purple-secondary {
    background-image: linear-gradient(134deg, #8a4dd2, #19456b);
    background-image: -webkit-gradient(linear, left top, right top, from(#8a4dd2), to(#19456b));
}

/*--------------------------------------------------------------*/
.badge {
    border-radius: 0;
    font-size: 12px;
    line-height: 1;
    padding: .375rem .5625rem;
    font-weight: normal
}

.badge-outline-primary {
    color: #16C79A;
    border: 1px solid #16C79A;
}

.badge-outline-secondary {
    color: #19456B;
    border: 1px solid #19456B;
}


.badge.badge-pill {
    border-radius: 10rem;
}

.badge-outline-info {
    color: #3da5f4;
    border: 1px solid #3da5f4;
}

.badge-outline-danger {
    color: #f1536e;
    border: 1px solid #f1536e;
}

.badge-outline-success {
    color: #00c689;
    border: 1px solid #00c689;
}

.badge-outline-warning {
    color: #fda006;
    border: 1px solid #fda006;
}

/*------Expand Button----------------------------*/
.btn-expand {
    width: 44px;
    height: 44px;
    transition: width 0.7s;
}

.btn-expand-md {
    width: 38px;
    height: 38px;
    transition: width 0.7s;
}

.btn-expand-sm {
    width: 32px;
    height: 32px;
    transition: width 0.7s;
}

.btn-expand:hover {
    width: 130px;
    transition: width 0.7s;
}

.btn-expand-md:hover {
    width: 120px;
    transition: width 0.7s;
}

.btn-expand-sm:hover {
    width: 110px;
    transition: width 0.7s;
}

.btn-expand i {
    opacity: 1;
    transition: opacity 0.5s;
    display: inline-block;
}

.btn-expand:hover i {
    opacity: 0;
    transition: opacity 0.5s;
    display: none;
}

.btn-expand .btn-text {
    opacity: 0;
    transition: opacity .5s;
    position: relative;
    width: 100%;
    display: none;
}

.btn-expand:hover .btn-text {
    opacity: 1;
    transition: opacity 0.5s;
    display: inline-block;
}

.card-title,
.modal-title {
    font-size: .875rem !important;
    font-weight: 700;
    text-transform: uppercase;
}

/*-------------Add new fecility card---------------------*/
.margin-tb-50 {
    margin: 52% 0%;
}

.add-round {
    border-radius: 100%;
    margin: 0 auto;
    border: 10px solid #d4dde5;
    margin-bottom: 10px;
    width: 115px;
    height: 115px;
}

.add-round i {
    margin: 36% 0%;
    color: #d4dde5;
}

/*-----FInanc my Business styles-----*/

.business-p {
    height: 65px;
    min-height: 65px;
}

.business-title {
    min-height: 72px;
    height: 72px;
}

.fb-height {
    min-height: 750px;
}

.f-13 {
    font-size: 13px;
}

.center-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

p.card-new-sub {
    color: #2a74b3;
}


.carde-new-p {
    font-size: 13px;
}

p.card-new-sub2 {
    color: #529987;
}

p.card-new-sub3 {
    color: #b5a574;
}

.b-card {
    min-height: 430px;
}

/* custom radio style*/
.custom-img-radio {

    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
}

.radio-img-input {
    display: none;
}

.radio-img-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    padding: 5px;
}

.radio-img-label:hover {
    border-color: rgba(22, 199, 154, 1);
    background-color: rgba(22, 199, 154, 0.1);
    /* Change border color on hover */
}

.radio-img-input:checked+.radio-img-label {
    border-color: rgba(22, 199, 154, 1);
    background-color: rgba(22, 199, 154, 0.1);
    /* Change border color when radio is checked */
}

.radio-image {
    width: 50px;
    /* Adjust image width */
    height: 50px;
    /* Adjust image height */
    margin-right: 10px;
}

.radio-img-text {
    font-size: 16px;
    /* Additional styling for the text if needed */
}


@media screen and (max-width: 865px) {
    .multi-step-progressbar-label {

        display: none;
        height: 5px;

    }
}
.scrollDiv {
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.scrollDiv table {
  border-spacing: 0;
}

.scrollDiv th {
  border-left: none;
  padding: 5px;
  width: 80px;
  min-width: 80px;
  position: sticky;
  top: 0;
  background: #fff;
  border-top: solid;
  border-bottom: solid;
  border-radius: 1px dashed #000;
  font-weight: normal;
  font-weight: 600;

}

.scrollDiv td {
  border-left: none;
  padding: 5px;
  width: 80px;
  min-width: 80px;
}

.scrollDiv th:nth-child(1),
.scrollDiv td:nth-child(1) {
  position: sticky;
  left: 0;
  width: 60px;
  min-width: 60px;

}

.scrollDiv th:nth-child(2),
.scrollDiv td:nth-child(2) {
  position: sticky;
  /* 1st cell left/right padding + 1st cell width + 1st cell left/right border width */
  /* 0 + 5 + 150 + 5 + 1 */
  left: 60px;
  width: 50px;
  min-width: 50px;
}

.scrollDiv th:nth-child(3),
.scrollDiv td:nth-child(3) {
  position: sticky;
  left: 110px;
  width: 50px;
  min-width: 140px;
}

.scrollDiv th:nth-child(4),
.scrollDiv td:nth-child(4) {
  position: sticky;
  left: 250px;
  width: 60px;
  min-width: 100px;
}

.scrollDiv th:nth-child(5),
.scrollDiv td:nth-child(5) {
  position: sticky;
  left: 350px;
  width: 60px;
  min-width: 100px;
}

.scrollDiv th:nth-child(6),
.scrollDiv td:nth-child(6) {
  position: sticky;
  left: 450px;
  width: 60px;
  min-width: 100px;
}

.scrollDiv th:nth-child(7),
.scrollDiv td:nth-child(7) {
  position: sticky;
  left: 550px;
  width: 60px;
  min-width: 100px;
}



.scrollDiv td:nth-child(1),
.scrollDiv td:nth-child(2),
.scrollDiv td:nth-child(3),
.scrollDiv td:nth-child(4),
.scrollDiv td:nth-child(5),
.scrollDiv td:nth-child(6),
.scrollDiv td:nth-child(7) {
  z-index: 5;
  background-color: #f1f5f9;

}

.scrollDiv th:nth-child(1),
.scrollDiv th:nth-child(2),
.scrollDiv th:nth-child(3),
.scrollDiv th:nth-child(4),
.scrollDiv th:nth-child(5),
.scrollDiv th:nth-child(6),
.scrollDiv th:nth-child(7) {
  z-index: 999;
  background-color: #cfdae6;

}

.scrollDiv td:nth-child(7) {
  border-right: 1px solid #ccc !important;


}

.scrollDiv th:nth-child(7) {
  border-right: 1px dotted #ccc;


}
/*-----Pay Me Now Button-------------------------*/

button.payme-primary {
    background-color: #12a57f;
    border-color: #119976;
    display: inline-block;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 2rem!important;
    padding: 12px 12px 12px 12px;
    align-items: center;
}

button.payme-primary img {
    width: 16px;
    margin-right: 6px;
}

button.payme-primary:hover {
    transition-timing-function: cubic-bezier(.4,0,1,1);
    transition-duration: .3s;
    outline: none;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}
button.payme-secondary {
  background-color: #19456b;
  border-color: #19456b;
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: 2rem!important;
  align-items: center;
  padding: 12px 12px 12px 12px;
}

button.payme-secondary img {
  width: 16px;
  margin-right: 6px;
}

button.payme-secondary:hover {
  transition-timing-function: cubic-bezier(.4,0,1,1);
  transition-duration: .3s;
  outline: none;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}
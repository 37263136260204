.rt-resizable-header-content {
  padding: 0;
}

.ReactTable {
  border: 0px !important;
}


.ReactTable .rt-td {
  flex: 1 0;
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;


  border-right: 0px solid !important;
}


.ReactTable .sticky-colum {
  overflow: visible !important;

}

.rt-thead {
  border-bottom: 1px solid #eff0f6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(221, 221, 221);
}

.rt-th {

  border-right: 0px solid !important;
}

.ReactTable .rt-thead .rt-td,
.ReactTable .rt-thead .rt-th {
  padding: 0.75rem;
}

.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-td,
.ReactTable .rt-th {
  border-right: 1px solid #eff0f6;

}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(88, 176, 126, 0.028) !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
}

.ReactTable .rt-tbody .rt-td .action-icons {
  margin-right: 6px !important;
}

.ReactTable .-pagination {
  box-shadow: none;
  border-top: 1px solid #eff0f6 !important;
}

.rt-table a i {
  color: rgba(25, 69, 107, .8) !important;
}

.ReactTable .rt-thead .rt-th,
table th {
  font-weight: 500;

}

.ReactTable .rt-thead .rt-th {
  padding: .75rem 0.375rem;
}

.rates-table .ReactTable.-striped .rt-tr.-odd {
  background: #fff !important;
}

.rates-table .ReactTable.-striped .rt-tr.-odd .rt-td a {
  color: rgba(25, 69, 107, 0.7);
}

.rates-table .ReactTable.-striped .rt-tr.-even {
  background: #fff;
}

.rates-table .ReactTable.-striped .rt-tr.row-active {
  background: #e8f9ef !important;
}

.rthfc.-highlight .rt-tr:hover .rt-td {
  background-color: rgba(18, 165, 126, 0.1) !important;

}

.rthfc.-highlight .rt-tr:hover .rt-td.sticky-colum {
  background-color: #f7f7f7 !important;

}
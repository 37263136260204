.form-inline .form-control {
  display: -webkit-box !important;
  width: auto;
  vertical-align: middle;
}

.select {
  width: 38%;
  margin-left: 14px;
}

.flag-select .selected--flag--option {
  cursor: pointer;
  padding: 0 0px;
}

.flag-select img {
  width: 2.3em;
  position: inherit;
}

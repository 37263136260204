

/*green-card1*/
.green-card1 {background: #fff;border-radius: 10px;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;}
.green-card-header {background: #58B07E;padding: 10px;border-radius: 10px 10px 0 0;}
.green-card-header .amount {font-weight: bolder;}
.green-card-header h3 { font-size: 24px !important;font-weight: 700 !important;color: #fff !important;}
.green-card-header label {color: #fff;}
.green-card1 label {font-size: 12px;}
.card-name {font-weight: bolder; color: #ccc;font-size: 16px;padding: 0;margin: 0;}
.card-title {font-size: 11px;}
.green-card1 .btn-green {background: #67ce93;color: #fff;}
.green-card1 .btn-green:hover {background: #64c58e;}

/*danger-card1*/
.danger-card1 {background: #fff;border-radius: 10px;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;}
.danger-card-header {background: #DB1F48;padding: 10px;border-radius: 10px 10px 0 0;}
.danger-card-header .amount {font-weight: bolder;}
.danger-card-header h3 { font-size: 24px !important ;font-weight: 700 !important;color: #fff;}
.danger-card-header label {color: #fff;}
.danger-card1 label {font-size: 12px;}
.card-name {font-weight: bolder; color: #ccc;font-size: 16px;padding: 0;margin: 0;}
.card-title {font-size: 12px;}

/*blue-card1*/
.blue-card1 {background: #fff;border-radius: 10px;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;}
.blue-card-header {background: #19456b;padding: 10px;border-radius: 10px 10px 0 0;}
.blue-card-header .amount {font-weight: bolder;}
.blue-card-header h3 { font-size: 24px;font-weight: 700;color: #fff;}
.blue-card-header label {color: #fff;}
.blue-card1 label {font-size: 12px;}
.card-name {font-weight: bolder; color: #ccc;font-size: 16px;padding: 0;margin: 0;}
.card-title {font-size: 12px;}

.card-btn { font-size:10px;border-radius: 12px;padding: 2px 10px;}
.btn-green {background: #58B07E;color: #fff;}
.btn-blue {background: #19456b;color: #fff;}
.green-highlight {color: #58B07E !important;}
.card__round {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.title-round-sec {
    display: inline-flex;
    margin: 1rem;
}

.small-round {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-top: 3px;
}

.w-80 {
    width: 80% !important;
}
@media only screen and (max-width: 768px) {
    .blue-card1 {
        margin-top: 20px;
    }
    
    .green-card1 {
        margin-top: 20px;
    }

    .danger-card1 {
        margin-top: 20px;
    }
  }
  .card-top-margin{
      margin-top:20px;
  }
  